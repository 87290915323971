import { Routes } from "@angular/router";
import { InvoicesComponent } from "./components/invoices/invoices.component";
import { MessagesComponent } from "./components/messages/messages.component";
import { ClientsComponent } from "./components/clients/clients.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { VaultProfilesComponent } from "./components/vault-profiles/vault-profiles.component";
import { MiscComponent } from "./components/misc/misc.component";
import { ParsingPatternsComponent } from "./components/parsing-patterns/parsing-patterns.component";
import { PaymentAccountsComponent } from "./components/payment-accounts/payment-accounts.component";
import { SupportComponent } from "./components/support/support.component";
import { BookingComponent } from "./components/booking/booking.component";
import { VaultOwnersComponent } from "./components/vault-owners/vault-owners.component";
import { VaultOwnerViewComponent } from "./components/vault-owner-view/vault-owner-view.component";
import { BalanceAccountsComponent } from "./components/balance-accounts/balance-accounts.component";
import { PlatformWalletsComponent } from "./components/platform-wallets/platform-wallets.component";
import { FundsOperationsComponent } from "./components/funds-operations/funds-operations.component";


export const routes: Routes = [
  { path: 'invoices', component: InvoicesComponent },
  { path: 'clients', component: ClientsComponent },
  { path: 'messages', component: MessagesComponent },
  { path: 'payment-accounts', component: PaymentAccountsComponent },
  { path: 'parsing-patterns', component: ParsingPatternsComponent },
  { path: 'vault-profiles', component: VaultProfilesComponent },
  { path: 'vault-owners', component: VaultOwnersComponent },
  { path: 'misc', component: MiscComponent },
  { path: 'balance-accounts', component: BalanceAccountsComponent },
  { path: 'platform-wallets', component: PlatformWalletsComponent },
  { path: 'funds-operations', component: FundsOperationsComponent },
  { path: 'booking', component: BookingComponent },
  { path: 'support', component: SupportComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'vault-owner-view', component: VaultOwnerViewComponent },
];
