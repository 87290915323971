<cds-modal cdsTheme="g100" size="sm" [open]="true" (overlaySelected)="closeModal()">
  <cds-modal-header (closeSelect)="closeModal()" style="margin-bottom: 1rem;">
    <h2 cdsModalHeaderLabel>Payment Accounts</h2>
    <h3 cdsModalHeaderHeading>Add account</h3>
  </cds-modal-header>
  <section cdsModalContent [cdsStack]="'vertical'" [hasForm]="true">
    <div [cdsStack]="'horizontal'" style="margin-bottom: 1rem;">
      <cds-dropdown
        cdsLayer
        [label]="'Vault profile'"
        [size]="'md'"
        placeholder="Select"
        (selected)="profileSelected($event)">
        <cds-dropdown-list [cdsTheme]="'g90'" [items]="vaultProfiles"></cds-dropdown-list>
      </cds-dropdown>
      <cds-dropdown
        cdsLayer
        [label]="'Bank'"
        [size]="'md'"
        placeholder="Select"
        (selected)="bankSelected($event)">
        <cds-dropdown-list [cdsTheme]="'g90'" [items]="banks"></cds-dropdown-list>
      </cds-dropdown>
    </div>
    <div [cdsStack]="'horizontal'" style="margin-bottom: 1rem;">
      <div class="cds--text-input__field-wrapper">
        <cds-label helperText="">
          Requisites
          <input cdsText placeholder="" [autocomplete]="false" [(ngModel)]="requisites">
        </cds-label>
      </div>
    </div>
    <div [cdsStack]="'horizontal'" style="margin-bottom: 1rem;">
      <cds-radio-group [legend]="'Status'" [orientation]="'horizontal'">
        <cds-radio value="Enable" [checked]="true" (click)="radioStatus(0)">Active</cds-radio>
        <cds-radio value="Disabled" (click)="radioStatus(1)">Disabled</cds-radio>
      </cds-radio-group>
      <cds-radio-group [legend]="'SBP'" [orientation]="'horizontal'">
        <cds-radio value="Yes" (click)="radioStatus(0)">Yes</cds-radio>
        <cds-radio value="No" [checked]="true" (click)="radioStatus(1)">No</cds-radio>
      </cds-radio-group>
    </div>
  </section>
  <cds-modal-footer>
    <button class="cds--btn cds--btn--secondary" (click)="closeModal()">Cancel</button>
    <button class="cds--btn cds--btn--primary" modal-primary-focus (click)="send()">Send</button>
  </cds-modal-footer>
</cds-modal>
