import { Component } from '@angular/core';
import { BaseModal } from "carbon-components-angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent extends BaseModal {

  public nickname?: string;
  public email?: number;

  constructor(private http: HttpClient) {
    super();
  }

  public send(): void {
    const obj2Send = {
      nickname: this.nickname,
      email: this.email
    }
    this.http.post(environment.API_URL + 'mgmt/clients', obj2Send)
      .subscribe(() => {
        super.closeModal();
      });
  }
}
