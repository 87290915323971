import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalService, TableHeaderItem, TableItem, TableModel } from "carbon-components-angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AddPatternComponent } from "../temp-modals/add-pattern/add-pattern.component";

@Component({
  selector: 'app-parsing-patterns',
  templateUrl: './parsing-patterns.component.html',
  styleUrls: ['./parsing-patterns.component.css']
})
export class ParsingPatternsComponent implements OnInit {

  public model = new TableModel();

  @ViewChild('overflowMenuItemTemplate', { static: false })
  protected overflowMenuItemTemplate: TemplateRef<any> | undefined;

  constructor(private http: HttpClient, private modalService: ModalService) {
  }

  ngOnInit(): void {
    this.model.header = [
      new TableHeaderItem({ data: 'id' }),
      new TableHeaderItem({ data: 'Bank' }),
      new TableHeaderItem({ data: 'Parsing pattern' }),
      new TableHeaderItem({ data: 'Status' }),
      new TableHeaderItem({ data: 'Actions' }),
    ];

    this.http.get(environment.API_URL + 'mgmt/parsing-patterns')
      .subscribe((data: any) => {
        this.http.get(environment.API_URL + 'mgmt/banks')
          .subscribe((bankdata: any) => {
            const banks = bankdata;


            this.model.data = data.map((item: any) => {
              const bank = banks.find((b: any) => b.id === item.bankDataId);
              return [
                new TableItem({ data: item.id }),
                new TableItem({ data: bank.bankTitle }),
                new TableItem({ data: item.regexpPattern }),
                new TableItem({ data: item.status }),
                new TableItem({ data: '', template: this.overflowMenuItemTemplate }),
              ];
            });
          })
      });
  }

  public openModal() {
    this.modalService.create({ component: AddPatternComponent, inputs: {} });
  }

  public deletePatternEntry(data: any) {
    this.http.post(environment.API_URL + `mgmt/parsing-patterns/${data.id}/remove`, { id: data.id })
      .subscribe((data: any) => console.log(data));
  }
}
