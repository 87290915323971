import { Component } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BaseModal } from "carbon-components-angular";

@Component({
  selector: 'app-add-vault-owner',
  templateUrl: './add-vault-owner.component.html',
  styleUrls: ['./add-vault-owner.component.css']
})
export class AddVaultOwnerComponent extends BaseModal {

  public nickname?: string;
  public email?: number;

  constructor(private http: HttpClient) {
    super();
  }

  public send(): void {
    const obj2Send = {
      nickname: this.nickname,
      email: this.email
    }
    this.http.post(environment.API_URL + 'mgmt/vault-owners', obj2Send)
      .subscribe(() => {
        super.closeModal();
      });
  }
}
