import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalService, TableHeaderItem, TableItem, TableModel } from "carbon-components-angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-funds-operations',
  templateUrl: './funds-operations.component.html',
  styleUrls: ['./funds-operations.component.css']
})
export class FundsOperationsComponent implements OnInit {

  public model = new TableModel();

  @ViewChild('overflowMenuItemTemplate', { static: false })
  protected overflowMenuItemTemplate: TemplateRef<any> | undefined;

  constructor(private http: HttpClient, private modalService: ModalService) {
  }

  ngOnInit(): void {
    this.model.header = [
      new TableHeaderItem({ data: 'id' }),
      new TableHeaderItem({ data: 'User id' }),
      new TableHeaderItem({ data: 'Wallet id' }),
      new TableHeaderItem({ data: 'Amount' }),
      new TableHeaderItem({ data: 'Type' }),
      new TableHeaderItem({ data: 'Status' }),
      new TableHeaderItem({ data: 'Registration Date' }),
      new TableHeaderItem({ data: 'Actions' }),
    ];

    this.http.get(environment.API_URL + 'mgmt/funds-operations')
      .subscribe((data: any) => {
        this.model.data = data.map((item: any) => {
          return [
            new TableItem({ data: item.id }),
            new TableItem({ data: item.userId }),
            new TableItem({ data: item.walletId }),
            new TableItem({ data: item.amount }),
            new TableItem({ data: item.type }),
            new TableItem({ data: item.status }),
            new TableItem({ data: new Date(item.createDate).toLocaleString() }),
            new TableItem({ data: item, template: this.overflowMenuItemTemplate }),
          ];
        });
      });
  }

  public manualConfirm(data: any): void {
    const confirm = {
      opId: data.id,
      userId: data.userId,
      amount: data.amount
    }
    this.http.post(environment.API_URL + `mgmt/deposits/${1}/manual`, confirm)
      .subscribe()
  }
}
