import { Component, Inject } from '@angular/core';
import { BaseModal } from "carbon-components-angular";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-reserve-wallet-modal',
  templateUrl: './reserve-wallet-modal.component.html',
  styleUrls: ['./reserve-wallet-modal.component.css']
})
export class ReserveWalletModalComponent extends BaseModal {

  public amount?: number;

  public wallet?: string;

  public secondSend: boolean = false;

  constructor(@Inject('userId') private userId: number, private http: HttpClient) {
    super();
  }

  public send(): void {
    if (!this.secondSend) {
      const obj2Send = {
        userId: this.userId,
        amount: this.amount
      }
      this.secondSend = true;
      this.http.post(environment.API_URL + 'mgmt/platform-wallets/reserve', obj2Send)
        .subscribe((data: any) => {
          this.wallet = data.address;
        });
    } else {
      super.closeModal();
    }
  }
}
