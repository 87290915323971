<div id="wrapper" cdsTheme="g100">
  <cds-header [brand]="'Bank-Roll-Hall'">
    <cds-hamburger *ngIf="true"></cds-hamburger>
    <cds-header-global>
      <cds-header-item>Manage</cds-header-item>
      <cds-header-action description="action">
        <svg cdsIcon="fade" size="20"></svg>
      </cds-header-action>
    </cds-header-global>
  </cds-header>
  <div id="board">
    <cds-sidenav>
<!--      <cds-sidenav-item [active]="false">Main</cds-sidenav-item>-->
      <cds-sidenav-item>Main</cds-sidenav-item>
      <cds-sidenav-item [useRouter]="true" [route]="['invoices']">Invoices</cds-sidenav-item>
      <cds-sidenav-item [useRouter]="true" [route]="['clients']">Clients</cds-sidenav-item>
      <cds-sidenav-menu title="Vault" [expanded]="false">
        <cds-sidenav-item [useRouter]="true" [route]="['messages']">Messages</cds-sidenav-item>
        <cds-sidenav-item [useRouter]="true" [route]="['payment-accounts']">Payment Accounts</cds-sidenav-item>
        <cds-sidenav-item [useRouter]="true" [route]="['parsing-patterns']">Parsing patterns</cds-sidenav-item>
        <cds-sidenav-item [useRouter]="true" [route]="['vault-profiles']">Vault Profiles</cds-sidenav-item>
        <cds-sidenav-item [useRouter]="true" [route]="['vault-owners']">Vault Owners</cds-sidenav-item>
        <cds-sidenav-item [useRouter]="true" [route]="['misc']">Misc</cds-sidenav-item>
      </cds-sidenav-menu>
      <cds-sidenav-item [useRouter]="true" [route]="['booking']">Booking</cds-sidenav-item>
      <cds-sidenav-item [useRouter]="true" [route]="['support']">Support</cds-sidenav-item>
      <cds-sidenav-menu title="Finance" [expanded]="false">
        <cds-sidenav-item>Deals</cds-sidenav-item>
        <cds-sidenav-item [useRouter]="true" [route]="['balance-accounts']">Balance Accounts</cds-sidenav-item>
        <cds-sidenav-item [useRouter]="true" [route]="['platform-wallets']">Platform Wallets</cds-sidenav-item>
        <cds-sidenav-item [useRouter]="true" [route]="['funds-operations']">Funds Operations</cds-sidenav-item>
      </cds-sidenav-menu>
      <cds-sidenav-item>Settings</cds-sidenav-item>
      <hr/>
      <cds-sidenav-menu title="Next features">
        <cds-sidenav-item>Organizations</cds-sidenav-item>
      </cds-sidenav-menu>
      <cds-sidenav-menu title="Roles">
        <cds-sidenav-item [useRouter]="true" [route]="['vault-owner-view']">Vault Owner</cds-sidenav-item>
      </cds-sidenav-menu>
    </cds-sidenav>
    <router-outlet></router-outlet>
  </div>
</div>
