import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { WSService } from "./websocket/ws.service";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private booking$: BehaviorSubject<any>;
  private booking: Array<any> = new Array<any>();
  private poolSize: number = 0;
  private available: number = 0;

  constructor(private httpClient: HttpClient, private wsService: WSService) {
    this.booking$ = new BehaviorSubject<Array<any>>(this.booking);
    this.httpClient.get(environment.API_URL + 'mgmt/booking')
      .subscribe((data: any) => {
        this.poolSize = data.poolSize;
        this.available = data.available;
        this.booking = data.poolData;
        this.booking$.next({
          poolSize: this.poolSize,
          available: this.available,
          booking: this.booking
        });
        this.listenBookingUpdate();
      });
  }

  public getBooking(): Observable<any> {
    return this.booking$;
  }

  private listenBookingUpdate(): void {
    this.wsService.on('pool-update')
      .subscribe((data: any) => {
        this.booking = data;
        // this.available = data.isFree ? this.available++ : this.available--;
        // this.booking.forEach(item => {
        //   if (item.data.id === data.accountData.id) {
        //     item.isFree = data.isFree;
        //   }
        // });
        this.booking$.next({
          poolSize: this.poolSize,
          available: this.available,
          booking: this.booking
        });
      });
  }
}
