import { Component } from '@angular/core';
import { BaseModal } from "carbon-components-angular";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-add-platform-wallet',
  templateUrl: './add-platform-wallet.component.html',
  styleUrls: ['./add-platform-wallet.component.css']
})
export class AddPlatformWalletComponent extends BaseModal {

  public address?: string;

  constructor(private http: HttpClient) {
    super();
  }

  public send(): void {
    const obj2Send = {
      address: this.address,
    }
    this.http.post(environment.API_URL + 'mgmt/platform-wallets', obj2Send)
      .subscribe(() => {
        super.closeModal();
      });
  }
}
