import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalService, TableHeaderItem, TableItem, TableModel } from "carbon-components-angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CreateVaultProfileComponent } from "../temp-modals/create-vault-profile/create-vault-profile.component";

@Component({
  selector: 'app-messages-accounts',
  templateUrl: './vault-profiles.component.html',
  styleUrls: ['./vault-profiles.component.css']
})
export class VaultProfilesComponent implements OnInit {

  public model = new TableModel();

  @ViewChild('overflowMenuItemTemplate', { static: false })
  protected overflowMenuItemTemplate: TemplateRef<any> | undefined;

  constructor(private http: HttpClient, private modalService: ModalService) {
  }

  ngOnInit(): void {
    this.model.header = [
      new TableHeaderItem({ data: 'id' }),
      new TableHeaderItem({ data: 'Holder name' }),
      new TableHeaderItem({ data: 'Phone number' }),
      new TableHeaderItem({ data: 'Status' }),
      new TableHeaderItem({ data: 'Actions' }),
    ];

    this.http.get(environment.API_URL + 'mgmt/vault-profiles')
      .subscribe((data: any) => {
        this.model.data = data.map((item: any) => {
          return [
            new TableItem({ data: item.id }),
            new TableItem({ data: item.holderName }),
            new TableItem({ data: `+${item.phoneNumber}` }),
            new TableItem({ data: item.status }),
            new TableItem({ data: '', template: this.overflowMenuItemTemplate }),
          ];
        });
      });
  }

  public openModal() {
    this.modalService.create({ component: CreateVaultProfileComponent, inputs: {} });
  }
}
