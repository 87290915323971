import { Inject, Injectable } from '@angular/core';
import { WebSocketSubject, WebSocketSubjectConfig } from "rxjs/internal/observable/dom/WebSocketSubject";
import { filter, interval, map, Observable, Observer, Subject, SubscriptionLike, takeWhile } from "rxjs";
import { share, distinctUntilChanged } from "rxjs/operators";
import { IWSConfig, IWSMessage, IWSService } from "./msg.interfaces";
import { config } from './ws.config';

@Injectable({
  providedIn: 'root'
})
export class WSService implements IWSService {

  private config: WebSocketSubjectConfig<IWSMessage<any>>;

  private webSocketSub: SubscriptionLike;
  private statusSub: SubscriptionLike;

  private webSocket$?: WebSocketSubject<IWSMessage<any>>;
  private connection$?: Observer<boolean>;
  private wsMessages$: Subject<IWSMessage<any>>;

  private reconnection$?: Observable<number>;
  private reconnectInterval: number;

  private isConnected?: boolean;

  public status: Observable<boolean>;

  constructor(@Inject(config) private wsConfig: IWSConfig) {
    this.wsMessages$ = new Subject<IWSMessage<any>>();

    this.reconnectInterval = 5000;

    this.config = {
      url: wsConfig.endpoint,
      openObserver: { next: () => this.connection$?.next(true) },
      closeObserver: { next: () => this.connection$?.next(false) }
    }

    this.status = new Observable<boolean>(observer => {
      this.connection$ = observer;
    }).pipe(share(), distinctUntilChanged());

    this.statusSub = this.status.subscribe(isConnected => {
      this.isConnected = isConnected;

      if (!this.reconnection$ && !isConnected) this.reconnect();
      else this.reconnection$ = undefined;
    });

    this.webSocketSub = this.wsMessages$.subscribe({ error: console.log });
    // null, (error: ErrorEvent) => console.log('Websocket error!', error) // DEPRECATED

    this.connect();
  }

  private connect(): void {
    this.webSocket$ = new WebSocketSubject<IWSMessage<any>>(this.config);

    this.webSocket$.subscribe({
      next: message => this.wsMessages$.next(message),
      error: () => { if (!this.reconnection$ && !this.isConnected) this.reconnect(); },
      complete: () => console.log('complete')
    });
    // this.send('connect','ready' );
  }

  private reconnect(): void {
    this.reconnection$ = interval(this.reconnectInterval)
      .pipe(takeWhile(() => !this.isConnected));

    this.reconnection$.subscribe({ next: () => { this.connect() }});
  }

  public connectionReset(): void {
    this.webSocket$?.complete();
    this.connect();
  }

  public on<T>(event: string): any {
    if (event) return this.wsMessages$.pipe(
      filter((msg: IWSMessage<T>) => msg.event === event),
      map((msg: IWSMessage<T>) => msg.data)
    );
  }

  public send(event: string, data: any): void {
    if (event) this.webSocket$?.next({ event, data });
    else console.log('Send error!');
  }
}
