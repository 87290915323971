import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalService, TableHeaderItem, TableItem, TableModel } from "carbon-components-angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AddPlatformWalletComponent } from "../add-platform-wallet/add-platform-wallet.component";

@Component({
  selector: 'app-platform-wallets',
  templateUrl: './platform-wallets.component.html',
  styleUrls: ['./platform-wallets.component.css']
})
export class PlatformWalletsComponent implements OnInit {

  public model = new TableModel();

  @ViewChild('overflowMenuItemTemplate', { static: false })
  protected overflowMenuItemTemplate: TemplateRef<any> | undefined;

  constructor(private http: HttpClient, private modalService: ModalService) {
  }

  ngOnInit(): void {
    this.model.header = [
      new TableHeaderItem({ data: 'id' }),
      new TableHeaderItem({ data: 'Ticker' }),
      new TableHeaderItem({ data: 'Network' }),
      new TableHeaderItem({ data: 'Address' }),
      new TableHeaderItem({ data: 'State' }),
      new TableHeaderItem({ data: 'Create date' }),
      new TableHeaderItem({ data: 'Action' }),
    ];

    this.http.get(environment.API_URL + 'mgmt/platform-wallets')
      .subscribe((data: any) => {
        this.model.data = data.map((item: any) => {
          return [
            new TableItem({ data: item.id }),
            new TableItem({ data: item.ticker }),
            new TableItem({ data: item.network }),
            new TableItem({ data: item.address }),
            new TableItem({ data: item.state }),
            new TableItem({ data: new Date(item.createDate).toLocaleString() }),
            new TableItem({ data: '', template: this.overflowMenuItemTemplate }),
          ];
        });
      });
  }

  public openModal() {
    this.modalService.create({ component: AddPlatformWalletComponent });
  }
}
