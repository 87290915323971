<cds-modal cdsTheme="g100" size="sm" [open]="true" (overlaySelected)="closeModal()">
  <cds-modal-header (closeSelect)="closeModal()" style="margin-bottom: 1rem;">
    <h2 cdsModalHeaderLabel>Platform wallet</h2>
    <h3 cdsModalHeaderHeading>Add wallet</h3>
  </cds-modal-header>
  <section cdsModalContent [cdsStack]="'vertical'" [hasForm]="true">
    <div [cdsStack]="'horizontal'" style="margin-bottom: 1rem;">
      <cds-label helperText="">
        Wallet address
        <input cdsText placeholder="USDT" [autocomplete]="false" [(ngModel)]="address">
      </cds-label>
    </div>
  </section>
  <cds-modal-footer>
    <button class="cds--btn cds--btn--secondary" (click)="closeModal()">Cancel</button>
    <button class="cds--btn cds--btn--primary" modal-primary-focus (click)="send()">Send</button>
  </cds-modal-footer>
</cds-modal>
