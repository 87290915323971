import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TableHeaderItem, TableItem, TableModel } from "carbon-components-angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  public model = new TableModel();

  @ViewChild('overflowMenuItemTemplate', { static: false })
  protected overflowMenuItemTemplate: TemplateRef<any> | undefined;

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.model.header = [
      new TableHeaderItem({ data: 'id' }),
      new TableHeaderItem({ data: 'Holder number' }),
      new TableHeaderItem({ data: 'Sender' }),
      new TableHeaderItem({ data: 'Message' }),
      new TableHeaderItem({ data: 'Status' }),
      new TableHeaderItem({ data: 'Date' }),
      new TableHeaderItem({ data: 'Actions' }),
    ];

    this.http.get(environment.API_URL + 'mgmt/vault-messages')
      .subscribe((data: any) => {
        this.model.data = data.map((item: any) => {
          return [
            new TableItem({ data: item.id }),
            new TableItem({ data: `+${item.phoneNumber}` }),
            new TableItem({ data: item.sender }),
            new TableItem({ data: item.message }),
            new TableItem({ data: item.status }),
            new TableItem({ data: new Date(item.createDate).toLocaleString() }),
            new TableItem({ data: '', template: this.overflowMenuItemTemplate }),
          ];
        });
      });
  }

}
