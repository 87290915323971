import { Component } from '@angular/core';
import {BaseModal} from "carbon-components-angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-create-messages-account',
  templateUrl: './create-vault-profile.component.html',
  styleUrls: ['./create-vault-profile.component.css']
})
export class CreateVaultProfileComponent extends BaseModal {

  public label: string = '';

  public vaultOwnerId?: number;
  public holderName?: string;
  public phoneNumber?: number;
  public status: number = 0;

  public vaultOwners?: any = [];

  constructor(private http: HttpClient) {
    super();

    this.http.get(environment.API_URL + 'mgmt/vault-owners')
      .subscribe((data: any) => {
        this.vaultOwners = data.map((item: any) => {
          return {
            ...item,
            content: item.nickname
          }
        });
      });
  }

  public send(): void {
    const obj2Send = {
      vaultOwnerId: this.vaultOwnerId,
      holderName: this.holderName,
      phoneNumber: this.phoneNumber,
      status: this.status
    }
    this.http.post(environment.API_URL + 'mgmt/vault-profiles', obj2Send)
      .subscribe(() => {
        super.closeModal();
      });
  }

  public ownerSelected($event: any) {
    this.vaultOwnerId = $event.item.id;
  }

  public radioStatus(status: number) {
    this.status = status;
  }
}
