import { Component } from '@angular/core';
import {BaseModal} from "carbon-components-angular";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-add-pattern',
  templateUrl: './add-pattern.component.html',
  styleUrls: ['./add-pattern.component.css']
})
export class AddPatternComponent extends BaseModal {

  public regexpPattern?: string;
  public bankDataId?: number;

  public banks?: any = [];

  constructor(private http: HttpClient) {
    super();
    this.http.get(environment.API_URL + 'mgmt/banks')
      .subscribe((data: any) => {
        this.banks = data.map((item: any) => {
          return {
            ...item,
            content: item.bankTitle
          }
        });
      });
  }

  public send(): void {
    const obj2Send = {
      regexpPattern: this.regexpPattern,
      bankDataId: this.bankDataId,

    }
    this.http.post(environment.API_URL + 'mgmt/parsing-patterns', obj2Send)
      .subscribe(() => {
        super.closeModal();
      });
  }


  bankSelected($event: any) {
    this.bankDataId = $event.item.id;
  }
}

