<div id="board-view" cdsLayer cdsGrid [useCssGrid]="true">
  <div cdsCol [columnNumbers]="{ lg: 6 }">
    <cds-table-container>
      <cds-table-header>
        <h3 cdsTableHeaderTitle>Banks</h3>
        <p cdsTableHeaderDescription>Available banks</p>
      </cds-table-header>
      <cds-table-toolbar>
        <cds-table-toolbar-content>
          <cds-table-toolbar-search [expandable]="true"></cds-table-toolbar-search>
          <button cdsButton="ghost" class="toolbar-action">
            <svg cdsIcon="settings" size="16" class="cds--toolbar-action__icon"></svg>
          </button>
          <button cdsButton="primary" size="sm" (click)="openModal()">Add bank<svg cdsIcon="add" size="20" class="cds--btn__icon"></svg></button>
        </cds-table-toolbar-content>
      </cds-table-toolbar>
      <cds-table [model]="model" [size]="'md'" [showSelectionColumn]="true">
        No data.
      </cds-table>
    </cds-table-container>
  </div>
</div>
<ng-template #overflowMenuItemTemplate let-data="data">
  <cds-overflow-menu>
    <cds-overflow-menu-option [disabled]="true">Edit</cds-overflow-menu-option>
    <cds-overflow-menu-option type="danger" (click)="deleteBankEntry(data)">Delete</cds-overflow-menu-option>
  </cds-overflow-menu>
</ng-template>
