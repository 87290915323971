import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { WSService } from "./websocket/ws.service";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  private invoices$: BehaviorSubject<Array<any>>;
  private invoices: Array<any> = new Array<any>();

  constructor(private http: HttpClient, private wsService: WSService) {
    this.invoices$ = new BehaviorSubject<Array<any>>(this.invoices);
    this.http.get(environment.API_URL + 'mgmt/invoices')
      .subscribe((data: any) => {
        this.invoices = data.reverse();
        this.invoices$.next(this.invoices);
        this.listenInvoices();
        this.listenInvoiceUpdate();
      });
  }

  private listenInvoices(): void {
    this.wsService.on('new-invoice')
      .subscribe((data: any) => {
        this.invoices.unshift(data);
        this.invoices$.next(this.invoices);
      });
  }

  public getInvoices(): Observable<Array<any>> {
    return this.invoices$;
  }

  private listenInvoiceUpdate(): void {
    this.wsService.on('invoice-updated')
      .subscribe((data: any) => {
        const invoiceIdx = this.invoices.findIndex(invoice => invoice.id === data.id);
        if (invoiceIdx === -1) {
          this.invoices.unshift(data);
        } else {
          this.invoices[invoiceIdx] = data;
        }
        this.invoices$.next(this.invoices);
      });
  }
}
