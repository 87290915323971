<div id="board-view" cdsLayer cdsGrid [useCssGrid]="true">
  <div cdsCol [columnNumbers]="{'lg': 16}">
    <cds-breadcrumb [noTrailingSlash]="true">
      <cds-breadcrumb-item href="/">Getting started</cds-breadcrumb-item>
    </cds-breadcrumb>
  </div>
  <br/>
  <div cdsCol [columnNumbers]="{'lg': 16}">
    <cds-table-container>
      <cds-table-header>
        <h3 cdsTableHeaderTitle>Invoices</h3>
        <p cdsTableHeaderDescription>All invoices from every client site</p>
      </cds-table-header>
      <cds-table-toolbar>
        <cds-table-toolbar-content>
          <cds-table-toolbar-search [expandable]="true"></cds-table-toolbar-search>
          <button cdsButton="ghost" class="toolbar-action">
            <svg cdsIcon="settings" size="16" class="cds--toolbar-action__icon"></svg>
          </button>
          <button cdsButton="primary" size="sm">
            Primary<svg cdsIcon="add" size="20" class="cds--btn__icon"></svg>
          </button>
        </cds-table-toolbar-content>
      </cds-table-toolbar>
      <cds-table [model]="model" [size]="'md'" [showSelectionColumn]="true">
        No data.
      </cds-table>
    </cds-table-container>
  </div>
</div>
<ng-template #overflowMenuItemTemplate>
  <cds-overflow-menu>
    <cds-overflow-menu-option [disabled]="true">Reject</cds-overflow-menu-option>
    <cds-overflow-menu-option>Open ticket</cds-overflow-menu-option>
  </cds-overflow-menu>
</ng-template>
