import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IWSConfig } from "./msg.interfaces";
import { config } from "./ws.config";


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class WSModule {
  public static config(wsConfig: IWSConfig): ModuleWithProviders<WSModule> {
    return {
      ngModule: WSModule,
      providers: [{ provide: config, useValue: wsConfig }]
    }
  }
}
