import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { InvoiceService } from "../../services/invoice.service";
import { TableHeaderItem, TableItem, TableModel } from "carbon-components-angular";

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit, AfterViewInit {

  public model = new TableModel();

  public invoiceStatus = ['New', 'Handle', 'WIP', 'Expired', 'Canceled', 'Complete'];

  @ViewChild('overflowMenuItemTemplate', { static: false })
  protected overflowMenuItemTemplate: TemplateRef<any> | undefined;

  constructor(private invoiceService: InvoiceService) { }

  ngOnInit(): void {
    this.model.header = [
      new TableHeaderItem({ data: 'id' }),
      new TableHeaderItem({ data: 'Partner' }),
      new TableHeaderItem({ data: 'Amount' }),
      new TableHeaderItem({ data: 'Status' }),
      new TableHeaderItem({ data: 'Deal Status' }),
      new TableHeaderItem({ data: 'Create Date' }),
      new TableHeaderItem({ data: 'Expire Date' }),
      new TableHeaderItem({ data: 'Actions' }),
    ];
  }

  ngAfterViewInit(): void {
    this.invoiceService.getInvoices()
      .subscribe(data => {
        this.model.data = data.map(item => {
          return this.prepareTableRow(item);
        });
      });
  }

  private prepareTableRow(item: any): any {
    return [
      new TableItem({ data: item.id }),
      new TableItem({ data: item.clientId }),
      new TableItem({ data: `${item.amount} ₽` }),
      new TableItem({ data: this.invoiceStatus[item.status] }),
      new TableItem({ data: item.dealStatus }),
      new TableItem({ data: new Date(item.createDate).toLocaleString() }),
      new TableItem({ data: new Date(item.expireDate).toLocaleString() }),
      new TableItem({ data: '', template: this.overflowMenuItemTemplate }),
    ];
  }

  private updateInvoiceStatus(invoice: any): any {
    this.model.data.forEach(item => {
      if (item[0].data === invoice.id) {
        item[5].data = invoice.status;
      }
    });
  }
}
