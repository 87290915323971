import { Component } from '@angular/core';
import { BaseModal } from "carbon-components-angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-add-payment-account',
  templateUrl: './add-payment-account.component.html',
  styleUrls: ['./add-payment-account.component.css']
})
export class AddPaymentAccountComponent extends BaseModal {

  public vaultProfileId?: number;
  public bankDataId?: number;
  public requisites?: string;
  public sbp: number = 1;
  public status: number = 0;

  public vaultProfiles?: any = [];
  public banks?: any = [];

  constructor(private http: HttpClient) {
    super();

    this.http.get(environment.API_URL + 'mgmt/vault-profiles')
      .subscribe((data: any) => {
        this.vaultProfiles = data.map((item: any) => {
          return {
            ...item,
            content: item.holderName
          }
        });
      });

    this.http.get(environment.API_URL + 'mgmt/banks')
      .subscribe((data: any) => {
        this.banks = data.map((item: any) => {
          return {
            ...item,
            content: item.bankTitle
          }
        });
      });
  }

  public send(): void {
    const obj2Send = {
      vaultProfileId: this.vaultProfileId,
      bankDataId: this.bankDataId,
      requisites: this.requisites,
      sbp: this.sbp,
      status: this.status
    }
    this.http.post(environment.API_URL + 'mgmt/bank-accounts', obj2Send)
      .subscribe(() => {
        super.closeModal();
      });
  }

  public profileSelected($event: any) {
    this.vaultProfileId = $event.item.id;
  }

  public bankSelected($event: any) {
    this.bankDataId = $event.item.id;
  }

  radioStatus(status: number) {
    this.status = status;
  }
}
