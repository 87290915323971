<cds-modal cdsTheme="g100" size="sm" [open]="true" (overlaySelected)="closeModal()">
  <cds-modal-header (closeSelect)="closeModal()" style="margin-bottom: 1rem;">
    <h2 cdsModalHeaderLabel>Pattern Data</h2>
    <h3 cdsModalHeaderHeading>Add pattern</h3>
  </cds-modal-header>
  <section cdsModalContent [cdsStack]="'vertical'" [hasForm]="true">
    <div [cdsStack]="'vertical'" style="margin-bottom: 1rem;">
      <cds-dropdown
        cdsLayer
        [label]="'Bank'"
        [size]="'md'"
        placeholder="Select"
        (selected)="bankSelected($event)">
        <cds-dropdown-list [cdsTheme]="'g90'" [items]="banks"></cds-dropdown-list>
      </cds-dropdown>
      <br>
      <div class="cds--text-input__field-wrapper">
        <cds-label helperText="">
          Pattern
          <input cdsText placeholder="Pattern" [autocomplete]="false" [(ngModel)]="regexpPattern">
        </cds-label>
      </div>
    </div>
  </section>
  <cds-modal-footer>
    <button class="cds--btn cds--btn--secondary" (click)="closeModal()">Cancel</button>
    <button class="cds--btn cds--btn--primary" modal-primary-focus (click)="send()">Add</button>
  </cds-modal-footer>
</cds-modal>
