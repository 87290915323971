import { Component } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { ModalService } from "carbon-components-angular";
import { ReserveWalletModalComponent } from "../reserve-wallet-modal/reserve-wallet-modal.component";

@Component({
  selector: 'app-vault-owner-view',
  templateUrl: './vault-owner-view.component.html',
  styleUrls: ['./vault-owner-view.component.css']
})
export class VaultOwnerViewComponent {

  public vaultOwners?: any = [];

  public vaultOwner?: any;

  public balanceAccount?: any;

  constructor(private http: HttpClient, private modalService: ModalService) {
    this.http.get(environment.API_URL + 'mgmt/vault-owners')
      .subscribe((data: any) => {
        this.vaultOwners = data.map((item: any) => {
          return {
            ...item,
            content: item.nickname
          }
        });
      });
  }

  public ownerSelected($event: any) {
    this.vaultOwner = $event.item;
    this.requestBalance($event.item.id);
  }

  private requestBalance(userId: number) {
    this.http.post(environment.API_URL + `mgmt/balance-accounts/${userId}`, { userId })
      .subscribe(data => {
        this.balanceAccount = data;
      });
  }

  public openModal() {
    this.modalService.create({ component: ReserveWalletModalComponent, inputs: { userId: this.vaultOwner.id } });
  }
}
