<div id="board-view" cdsLayer cdsGrid [useCssGrid]="true">
  <div cdsCol [columnNumbers]="{'lg': 16}">
    <cds-breadcrumb [noTrailingSlash]="true">
      <cds-breadcrumb-item href="/">Getting started</cds-breadcrumb-item>
    </cds-breadcrumb>
  </div>
  <br/>
  <div cdsCol [columnNumbers]="{'lg': 16}" [cdsStack]="'vertical'">
    <cds-dropdown
      cdsLayer
      [label]="'Vault owner'"
      [size]="'md'"
      placeholder="Select"
      (selected)="ownerSelected($event)">
      <cds-dropdown-list [cdsTheme]="'g90'" [items]="vaultOwners"></cds-dropdown-list>
    </cds-dropdown>
    <br/>
    <cds-tile>
      <h1>{{ vaultOwner?.nickname }}</h1>
      <br/>
      <div>Your deposit: </div><h3>{{ balanceAccount?.amount }}</h3>
      <br/>
      <button *ngIf="vaultOwner" cdsButton="primary" size="lg" (click)="openModal()">
        Top-up deposit
      </button>
    </cds-tile>
  </div>
</div>

