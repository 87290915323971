import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalService, TableHeaderItem, TableItem, TableModel } from "carbon-components-angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AddTicketComponent  } from "../temp-modals/add-ticket/add-ticket.component";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  public model = new TableModel();

  @ViewChild('overflowMenuItemTemplate', { static: false })
  protected overflowMenuItemTemplate: TemplateRef<any> | undefined;

  constructor(private http: HttpClient, private modalService: ModalService) {
  }

  ngOnInit(): void {
    this.model.header = [
      new TableHeaderItem({ data: 'id' }),
      new TableHeaderItem({ data: 'Ticket name' }),
      new TableHeaderItem({ data: 'Ticket author' }),
      new TableHeaderItem({ data: 'Ticket description' }),
      new TableHeaderItem({ data: 'Status' }),
      new TableHeaderItem({ data: 'Actions' }),
    ];

    this.http.get(environment.API_URL + 'mgmt/support')
      .subscribe((data: any) => {
        this.model.data = data.map((item: any) => {
          return [
            new TableItem({ data: item.id }),
            new TableItem({ data: item.ticketName }),
            new TableItem({ data: item.ticketAuthor }),
            new TableItem({ data: `+${item.ticketDescription}` }),
            new TableItem({ data: item.status }),
            new TableItem({ data: '', template: this.overflowMenuItemTemplate }),
          ];
        });
      });
  }

  public openModal() {
    this.modalService.create({ component: AddTicketComponent, inputs: {} });
  }
}
