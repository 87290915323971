import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideRouter, RouterOutlet } from "@angular/router";
import { routes } from "./app.routes";
import { HttpClientModule } from "@angular/common/http";

import { WSModule } from "./services/websocket/ws.module";
import { environment } from "../environments/environment";

import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import {
    BreadcrumbModule, ButtonModule, DialogModule, DropdownModule,
    GridModule,
    HeaderModule, IconModule, InputModule,
    LayerModule, LayoutModule, ModalModule, PlaceholderModule, RadioModule,
    SideNavModule, TableModule,
    ThemeModule, TilesModule
} from "carbon-components-angular";
import { MessagesComponent } from './components/messages/messages.component';
import { ClientsComponent } from './components/clients/clients.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { SettingsComponent } from './components/settings/settings.component';
import { VaultProfilesComponent } from './components/vault-profiles/vault-profiles.component';
import { CreateVaultProfileComponent } from './components/temp-modals/create-vault-profile/create-vault-profile.component';
import { FormsModule } from "@angular/forms";
import { MiscComponent } from './components/misc/misc.component';
import { AddBankComponent } from './components/temp-modals/add-bank/add-bank.component';
import { ParsingPatternsComponent } from "./components/parsing-patterns/parsing-patterns.component";
import { AddPatternComponent } from "./components/temp-modals/add-pattern/add-pattern.component";
import { PaymentAccountsComponent } from './components/payment-accounts/payment-accounts.component';
import { AddPaymentAccountComponent } from './components/add-payment-account/add-payment-account.component';
import { AddClientComponent } from './components/temp-modals/add-client/add-client.component';
import { AddTicketComponent } from './components/temp-modals/add-ticket/add-ticket.component';
import { SupportComponent } from "./components/support/support.component";
import { BookingComponent } from './components/booking/booking.component';
import { VaultOwnersComponent } from './components/vault-owners/vault-owners.component';
import { AddVaultOwnerComponent } from './components/add-vault-owner/add-vault-owner.component';
import { VaultOwnerViewComponent } from './components/vault-owner-view/vault-owner-view.component';
import { BalanceAccountsComponent } from './components/balance-accounts/balance-accounts.component';
import { PlatformWalletsComponent } from './components/platform-wallets/platform-wallets.component';
import { AddPlatformWalletComponent } from './components/add-platform-wallet/add-platform-wallet.component';
import { ReserveWalletModalComponent } from './components/reserve-wallet-modal/reserve-wallet-modal.component';
import { FundsOperationsComponent } from './components/funds-operations/funds-operations.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MessagesComponent,
    ClientsComponent,
    InvoicesComponent,
    SettingsComponent,
    VaultProfilesComponent,
    CreateVaultProfileComponent,
    MiscComponent,
    AddBankComponent,
    ParsingPatternsComponent,
    AddPatternComponent,
    PaymentAccountsComponent,
    AddPaymentAccountComponent,
    AddClientComponent,
    AddTicketComponent,
    SupportComponent,
    BookingComponent,
    VaultOwnersComponent,
    AddVaultOwnerComponent,
    VaultOwnerViewComponent,
    BalanceAccountsComponent,
    PlatformWalletsComponent,
    AddPlatformWalletComponent,
    ReserveWalletModalComponent,
    FundsOperationsComponent
  ],
    imports: [
        BrowserModule,
        HttpClientModule,
        WSModule.config({ endpoint: environment.wsEndpoint }),
        HeaderModule,
        SideNavModule,
        ThemeModule,
        LayerModule,
        GridModule,
        BreadcrumbModule,
        TableModule,
        ButtonModule,
        IconModule,
        DialogModule,
        RouterOutlet,
        ModalModule,
        PlaceholderModule,
        InputModule,
        LayoutModule,
        FormsModule,
        RadioModule,
        DropdownModule,
        TilesModule
    ],
  providers: [provideRouter(routes)],
  bootstrap: [AppComponent]
})
export class AppModule { }
