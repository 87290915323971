import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BookingService } from "../../services/booking.service";
import { TableHeaderItem, TableItem, TableModel } from "carbon-components-angular";

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit, AfterViewInit {

  public model = new TableModel();

  @ViewChild('overflowMenuItemTemplate', { static: false })
  protected overflowMenuItemTemplate: TemplateRef<any> | undefined;

  constructor(private bookingService: BookingService) { }

  ngOnInit(): void {
    this.model.header = [
      new TableHeaderItem({ data: 'Is free' }),
      new TableHeaderItem({ data: 'Phone number' }),
      new TableHeaderItem({ data: 'Holder' }),
      new TableHeaderItem({ data: 'Requisites' }),
      new TableHeaderItem({ data: 'Sbp' }),
      new TableHeaderItem({ data: 'Bank id' }),
      new TableHeaderItem({ data: 'Owner id' }),
      new TableHeaderItem({ data: 'Actions' }),
    ]
  }

  ngAfterViewInit(): void {
    this.bookingService.getBooking()
      .subscribe(data => {
        this.model.data = data.booking.map((item: any) => {
          return this.prepareTableRow(item);
        });
      });
  }

  private prepareTableRow(item: any) {
    return [
      new TableItem({ data: item.isFree }),
      new TableItem({ data: item.data.phoneNumber }),
      new TableItem({ data: item.data.holderName }),
      new TableItem({ data: item.data.requisites }),
      new TableItem({ data: item.data.sbp ? 'Yes' : 'No' }),
      new TableItem({ data: item.data.bankDataId }),
      new TableItem({ data: item.data.ownerId }),
      new TableItem({ data: '', template: this.overflowMenuItemTemplate }),
    ]
  }
}
