<cds-modal cdsTheme="g100" size="sm" [open]="true" (overlaySelected)="closeModal()">
  <cds-modal-header (closeSelect)="closeModal()" style="margin-bottom: 1rem;">
    <h2 cdsModalHeaderLabel>Client</h2>
    <h3 cdsModalHeaderHeading>Add client accounts</h3>
  </cds-modal-header>
  <section cdsModalContent [cdsStack]="'vertical'" [hasForm]="true">
    <div [cdsStack]="'horizontal'" style="margin-bottom: 1rem;">
      <div class="cds--text-input__field-wrapper">
        <cds-label helperText="">
          Nickname
          <input cdsText placeholder="Nickname" [autocomplete]="false" [(ngModel)]="nickname">
        </cds-label>
      </div>
      <div class="cds--text-input__field-wrapper">
        <cds-label helperText="">
          Email
          <input cdsText placeholder="xxxxxxx@mail.com" [autocomplete]="false" [(ngModel)]="email">
        </cds-label>
      </div>
    </div>
  </section>
  <cds-modal-footer>
    <button class="cds--btn cds--btn--secondary" (click)="closeModal()">Cancel</button>
    <button class="cds--btn cds--btn--primary" modal-primary-focus (click)="send()">Add account</button>
  </cds-modal-footer>
</cds-modal>
