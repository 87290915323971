import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalService, TableHeaderItem, TableItem, TableModel } from "carbon-components-angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-balance-accounts',
  templateUrl: './balance-accounts.component.html',
  styleUrls: ['./balance-accounts.component.css']
})
export class BalanceAccountsComponent implements OnInit {

  public model = new TableModel();

  @ViewChild('overflowMenuItemTemplate', { static: false })
  protected overflowMenuItemTemplate: TemplateRef<any> | undefined;

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.model.header = [
      new TableHeaderItem({ data: 'id' }),
      new TableHeaderItem({ data: 'User id' }),
      new TableHeaderItem({ data: 'Amount' }),
      new TableHeaderItem({ data: 'Create date' }),
      new TableHeaderItem({ data: 'Actions' }),
    ];

    this.http.get(environment.API_URL + 'mgmt/balance-accounts')
      .subscribe((data: any) => {
        this.model.data = data.map((item: any) => {
          return [
            new TableItem({ data: item.id }),
            new TableItem({ data: item.userId }),
            new TableItem({ data: item.amount }),
            new TableItem({ data: new Date(item.createDate).toLocaleString() }),
            new TableItem({ data: '', template: this.overflowMenuItemTemplate }),
          ];
        });
      });
  }
}
