import { Component } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BaseModal } from "carbon-components-angular";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.css']
})
export class AddBankComponent extends BaseModal {

  public bankTitle?: string;
  public senderNumber?: number;

  constructor(private http: HttpClient) {
    super();
  }

  public send(): void {
    const obj2Send = {
      bankTitle: this.bankTitle,
      senderNumber: this.senderNumber
    }
    this.http.post(environment.API_URL + 'mgmt/banks', obj2Send)
      .subscribe(() => {
        super.closeModal();
      });
  }
}
