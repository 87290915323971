<div id="board-view" cdsLayer cdsGrid>
  <div cdsCol [columnNumbers]="{'lg': 16}">
    <cds-breadcrumb [noTrailingSlash]="true">
      <cds-breadcrumb-item href="/">Getting started</cds-breadcrumb-item>
    </cds-breadcrumb>
  </div>
  <br/>
  <div cdsCol [columnNumbers]="{'lg': 16}">
    <cds-table-container>
      <cds-table-header>
        <h3 cdsTableHeaderTitle>Funds Operations</h3>
        <p cdsTableHeaderDescription>Deposit and withdraw operations</p>
      </cds-table-header>
      <cds-table-toolbar>
        <cds-table-toolbar-content>
          <cds-table-toolbar-search [expandable]="true"></cds-table-toolbar-search>
          <button cdsButton="ghost" class="toolbar-action">
            <svg cdsIcon="settings" size="16" class="cds--toolbar-action__icon"></svg>
          </button>
        </cds-table-toolbar-content>
      </cds-table-toolbar>
      <cds-table [model]="model" [size]="'md'" [showSelectionColumn]="true">
        No data.
      </cds-table>
    </cds-table-container>
  </div>
</div>
<ng-template #overflowMenuItemTemplate let-data="data">
  <cds-overflow-menu>
    <cds-overflow-menu-option [disabled]="true">Reject</cds-overflow-menu-option>
    <cds-overflow-menu-option (click)="manualConfirm(data)">Manual confirm</cds-overflow-menu-option>
  </cds-overflow-menu>
</ng-template>
