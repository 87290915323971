import { Component } from '@angular/core';
import { BaseModal } from "carbon-components-angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-add-ticket',
  templateUrl: './add-ticket.component.html',
  styleUrls: ['./add-ticket.component.css']
})
export class AddTicketComponent extends BaseModal {

  public ticketName?: string;
  public ticketDescription?: string;

  constructor(private http: HttpClient) {
    super();
  }

  public send(): void {
    const obj2Send = {
      ticketName: this.ticketName,
      ticketDescription: this.ticketDescription
    }
    this.http.post(environment.API_URL + 'mgmt/support', obj2Send)
      .subscribe(() => {
        super.closeModal();
      });
  }
}
